<template>
  <v-container class="pa-0 text-center fill-height" fluid>
    <v-row
      class="white fill-height"
      align-content="center"
      justify="center"
      no-gutters
    >
      <v-col cols="12">
        <v-img alt="Success" contain height="150" src="../assets/error.jpg" />
      </v-col>
      <v-col cols="12" class="mt-2">
        <v-row no-gutters>
          <v-col cols="12" class="text-center">
            <p class="font-weight-bold mb-1" style="color: #3d3f3c">
              {{ errormsg == '' || errormsg == null?'':errormsg }}
            </p>
          </v-col>
          <v-col cols="12">
            <span class="font-weight-bold mb-1" style="color: #3d3f3c">
              Redirecting to Active Session...
            </span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      errormsg: "",
    };
  },
  mounted() {
    this.errormsg = this.$route.params.data.message;
  },
};
</script>

<style lang="scss" scoped>
</style>